import { createReducer } from "@reduxjs/toolkit";
import {
  GET_WAITLIST_DATA_REQUEST,
  GET_WAITLIST_DATA_REQUEST_FAIL,
  GET_WAITLIST_DATA_REQUEST_SUCCESS,
  GET_WAITLIST_DATA_REQUEST_ERROR,
} from "../Variables/Waitlist";

const initialState = {
  loading: false,
  waitlist: [],
};

export const waitListReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_WAITLIST_DATA_REQUEST, (state) => {
      state.loading = true;
    })
    .addCase(GET_WAITLIST_DATA_REQUEST_FAIL, (state) => {
      state.loading = false;
    })
    .addCase(GET_WAITLIST_DATA_REQUEST_SUCCESS, (state, action) => {
      state.loading = false;
      state.waitlist = action.payload;
    })
    .addCase(GET_WAITLIST_DATA_REQUEST_ERROR, (state) => {
      state.loading = false;
    });
});
