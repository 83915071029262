import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux/es/hooks/useSelector";
import "./scss/style.scss";
import "./css/main.css";
// import PrivateRoute from './PrivateRoute';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  // const isloggedIn = document.cookie;
  // const isAuthenticated = useSelector((state) => state.isAuthenticated);
  return (
    <BrowserRouter>
      <ToastContainer position="top-center" theme="colored" />
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/404" element={<Page404 />} />
          <Route exact path="/500" element={<Page500 />} />
          <Route path="*" element={<DefaultLayout />} />
          {/* <Route
            path="*"
            element={
              isloggedIn ? <DefaultLayout /> : <Navigate to={"/login"} />
            }
          /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
