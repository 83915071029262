// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: true,
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

// =================

// import { createStore } from "redux";

// const initialState = {
//   isAuthenticated: false,
// };

// const changeState = (state = initialState, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       return { isAuthenticated: true };
//     default:
//       return state;
//   }
// };

// const store = createStore(changeState);
// export default store;
import { createSlice, configureStore } from "@reduxjs/toolkit";
import { OnboradingReducer } from "./redux/reducer/onboardingreducer";
import { waitListReducer } from "./redux/reducer/waitlistreducer";

const onboardingSlice = createSlice({
  name: "approveOnboardings",
  initialState: {
    approvedOnboardings: [],
  },
  reducers: {
    setApprovedOnboardings: (state, action) => {
      state.approvedOnboardings = action.payload;
      console.log(action.payload, "redux");
    },
  },
});

const store = configureStore({
  reducer: {
    setApprovedOnboardings: onboardingSlice.reducer,
    onboarding: OnboradingReducer,
    waitlist: waitListReducer,
  },
});
export const { setApprovedOnboardings } = onboardingSlice.actions;

export default store;
